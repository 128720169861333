import axios from 'axios';

const getSsoLoginUrl = () => `${axios.defaults.baseURL || ''}/api/sso?callbackUrl=${window.location.origin}/callback`;
const getSsoLogoutUrl = () => `${axios.defaults.baseURL || ''}/api/sso/logout?callbackUrl=${window.location.origin}/callback`;
const getSsoAppInitUrl = (appId) => `${axios.defaults.baseURL || ''}/api/sso?applicationId=${appId}&callbackUrl=${window.location.origin}/callback?init=true`;
const getSsoAccessToken = (token) => axios.get(`/api/sso/token?requestToken=${token}`);

export {
    getSsoLoginUrl,
    getSsoLogoutUrl,
    getSsoAppInitUrl,
    getSsoAccessToken
}